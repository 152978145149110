import {
  EditorReadyFn,
  EditorScriptFlowAPI,
  FlowEditorSDK,
} from '@wix/yoshi-flow-editor';
import type { AppManifest, ContextParams } from '@wix/platform-editor-sdk';
import { onEditorReady } from './editor-app/editorReady';
import { modifyAppManifest } from './editor-app/manifest/modifyAppManifest';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefId,
  options,
  flowAPI,
) => {
  return onEditorReady(editorSDK, appDefId, options, flowAPI);
};

export const getAppManifest = async (
  options,
  editorSDK: FlowEditorSDK,
  contextParams: ContextParams,
  flowAPI: EditorScriptFlowAPI,
): Promise<AppManifest> => {
  return modifyAppManifest(options, editorSDK, flowAPI);
};

export const exports = (editorSDK: FlowEditorSDK) => ({});
