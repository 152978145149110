import {
  EditorScriptFlowAPI,
  FlowEditorSDK,
  FlowPlatformOptions,
} from '@wix/yoshi-flow-editor';
import { handleFirstInstall } from './first-install/handleFirstInstall';
import { ORGANIZE_MEDIA_INITIAL_SETTINGS } from '../constants/organizeMediaConstants';
import { updateOrganizeMediaSettingsPublicData } from '../components/fastGalleryPolyWidget/panels/helpers/organizeMediaGeneralUtils';
import {
  fastGalleryPluginsAppDefinitionId,
  fastGalleryPolyPluginWidgetId,
  fastGalleryPolyWidgetId,
  fastGallerySlotName,
} from '../constants/generalConstants';

export const onEditorReady = async (
  editorSDK: FlowEditorSDK,
  appDefId: string,
  options: FlowPlatformOptions,
  flowAPI: EditorScriptFlowAPI,
): Promise<void> => {
  await editorSDK.addEventListener('componentAddedToStage', async (event) => {
    const serializedComponent = await editorSDK.components.serialize('token', {
      componentRef: event.detail.compRef,
    });

    if (serializedComponent?.data?.widgetId === fastGalleryPolyWidgetId) {
      await editorSDK.tpa.widgetPlugins.addWidgetPlugin('', {
        widgetPluginPointer: {
          appDefinitionId: fastGalleryPluginsAppDefinitionId,
          widgetId: fastGalleryPolyPluginWidgetId,
        },
        slotId: fastGallerySlotName,
        widgetId: fastGalleryPolyWidgetId,
      });
    }
  });
  if (options.firstInstall) {
    handleFirstInstall(editorSDK, appDefId, options, flowAPI);
    try {
      updateOrganizeMediaSettingsPublicData({
        editorSDK,
        updatedSettings: ORGANIZE_MEDIA_INITIAL_SETTINGS,
      });
    } catch (e) {}
  }
};
