import { FlowAPI, FlowEditorSDK, WidgetBuilder } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';
import { Logger } from '@wix/yoshi-flow-editor/bi';

const setDesktopGFPP = (
  widgetBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  flowAPI: FlowAPI,
  biLogger: Logger,
) => {
  const { t } = flowAPI.translations;
  widgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: t('Fast_Gallery_GFPP_Main_Action_1'),
      onClick: async ({ detail }) => {
        const { componentRef } = detail;
        const galleryId = (
          await editorSDK.document.controllers.getData('', {
            controllerRef: componentRef,
          })
        ).config.galleryId;
        editorSDK.editor
          .openModalPanel('', {
            title: t('OImages_Main_Title'),
            url: getPanelUrl(
              'fastGalleryPolyWidget',
              'FastGalleryOrganizeMediaPanel',
            ),
            height: '90%' as any,
            width: '90%' as any,
            initialData: {
              componentRef,
              galleryId,
            },
            centered: true,
          })
          .then(() => {
            editorSDK.document.application.livePreview.refresh('', {
              shouldFetchData: true,
              source: '',
            });
          });
      },
    })
    .set('connect', {
      behavior: 'HIDE',
    })
    .set('widgetPlugins', { behavior: 'HIDE' })
    .set('add', {
      behavior: 'HIDE',
    });
};

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  const biLogger = flowAPI.essentials.biLoggerFactory().logger();
  setDesktopGFPP(widgetBuilder, editorSDK, flowAPI, biLogger);

  widgetBuilder.set({
    displayName: 'Widget fastGalleryPolyWidget',
    nickname: 'fastGalleryPolyWidgetWidget',
  });
};
