import { OrganizeMediaSettingsType } from "../types/organizedMediaTypes";

export const organizeMediaContentKeys = {
  emptySearchResultsTitle: 'OMedia_Search_No_Results_Title',
  emptySearchResultsSubtitle: 'OMedia_Search_No_Results_Subtitle',
  selectionSelectAll: 'OImages_SelectAll_Label',
  selectionDeselectAll: 'OImages_Deselect_Label',
  selectionDelete: 'OImages_Remove_Label',
  selectionDefaultSubtitle: 'OImages_Main_Sub_Title',
  selectionSelected: 'OImages_Selected_Label_With_Items_Count',
  sortLabel: 'OMedia_Sorting_Panel_Title_New',
  sortByTitleAsc: 'OMedia_Sorting_Panel_Title_A_Z',
  sortByTitleDesc: 'OMedia_Sorting_Panel_Title_Z_A',
  sortByFilenameAsc: 'OMedia_Sorting_Panel_Filename_A_Z',
  sortByFilenameDesc: 'OMedia_Sorting_Panel_Filename_Z_A',
  searchPlaceholder: 'OImages_Search_Default_Text',
  settingsTitle: 'OImages_Settings_Title',
  settingsNewItemsDirectionLabel: 'OImages_Settings_NewImages_Title',
  settingsNewItemsDirectionStart: 'OImages_Settings_NewImages_Start',
  settingsNewItemsDirectionEnd: 'OImages_Settings_NewImages_End',
  settingsNewItemsTitleLabel: 'OImages_Settings_NewImages_Default_Name_Label',
  settingsNewItemsTitleEmpty: 'OImages_Settings_NewImages_Default_Name_Empty',
  settingsNewItemsTitleFilename:
    'OImages_Settings_NewImages_Default_Name_File_Name',
  itemTitleLabel: 'OImages_Image_Title',
  itemTitleTooltip: 'OImages_Image_Tooltip',
  itemTitlePlaceholder: 'OImages_Image_DText',
  itemLinkLabel: 'OImages_Link_Title',
  itemLinkPlaceholder: 'OImages_Link_Title_Placeholder',
  itemLinkNameLabel: 'OImages_Link_Description',
  itemLinkNameTooltip: 'OImages_Link_Description_Tooltip',
  itemFocalPointTooltip: 'OImages_Focal_Point',
  masonryLayoutTooltip: 'OMedia_Settings_Layout_Masonry_View',
  gridLayoutTooltip: 'OMedia_Settings_Layout_Grid_View',
  itemAltLabel: 'Organize_Media_Alt_Title',
  itemAltTooltip: 'Organize_Media_Alt_Tooltip',
  itemAltPlaceholder: 'OImages_AltText_Placeholder',
};

export const ORGANIZE_MEDIA_ITEM_EDITING_OPTION = {
  link: true,
  title: true,
  description: false,
  focalPoint: true,
  alt: true,
  externalLink: false,
} as const;

export enum LINK_PANEL_LINK_TYPES {
  PAGE_LINK = 'PageLink',
  DYNAMIC_PAGE_LINK = 'DynamicPageLink',
  TPA_PAGE_LINK = 'TpaPageLink',
  ANCHOR_LINK = 'AnchorLink',
  EXTERNAL_LINK = 'ExternalLink',
  EMAIL_LINK = 'EmailLink',
  PHONE_LINK = 'PhoneLink',
  DOCUMENT_LINK = 'DocumentLink',
}

export enum WIX_LINK_DATA_LINK_TYPE {
  PAGE = 'page',
  EXTERNAL = 'external',
  ANCHOR = 'anchor',
  EMAIL = 'email',
  PHONE = 'phone',
  DOCUMENT = 'document',
  DYNAMIC_PAGE = 'dynamicPage',
  TPA_PAGE = 'tpaPage',
}

export const ORGANIZE_MEDIA_INITIAL_SETTINGS: OrganizeMediaSettingsType = {
  ADD_ITEMS_DIRECTION: 'GALLERY_START',
  NAME_NEW_ITEMS: 'EMPTY',
};
