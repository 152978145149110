import {
  ComponentRef,
  EditorScriptFlowAPI,
  FlowEditorSDK,
  FlowPlatformOptions,
} from '@wix/yoshi-flow-editor';
import { createFastGallery } from './utils/installationUtils';
import { FastGalleryService } from '../../services/fastGalleryService';
import { Logger } from '@wix/yoshi-flow-editor/bi';
import { galleryComponentIDsMappingSrc174Evid5 } from '@wix/bi-logger-photography-data/v2';

const reportGalleryIdToCompIdBI = async (
  editorSDK: FlowEditorSDK,
  biLogger: Logger,
  galleryCompRef: ComponentRef,
  createdDraftGalleryId: string,
) => {
  try {
    let componentRef = galleryCompRef;
    // We need to get the gallery component id to report it to the BI
    for (let index = 0; index < 5; index++) {
      [componentRef] = await editorSDK.components.getChildren('token', {
        componentRef,
      });
      const component = await editorSDK.components.serialize('token', {
        componentRef,
      });

      if (component.componentType === 'wixui.FastGallery') {
        biLogger.report(
          galleryComponentIDsMappingSrc174Evid5({
            galleryId: createdDraftGalleryId,
            compId: componentRef?.id,
          }),
        );

        break;
      }
    }
  } catch (error) {}
};

export async function handleFirstInstall(
  editorSDK: FlowEditorSDK,
  appDefId: string,
  options: FlowPlatformOptions,
  flowAPI: EditorScriptFlowAPI,
): Promise<void> {
  try {
    const biLogger = options.essentials.biLoggerFactory().logger();
    const createdDraftGalleryId =
      await FastGalleryService.createGalleryInstance(flowAPI.httpClient);
    if (!createdDraftGalleryId) {
      throw new Error('no galleryId returned from the server');
    } else {
      const widgetRef = await createFastGallery(editorSDK);
      const [galleryCompRef] = await editorSDK.components.getChildren('token', {
        componentRef: widgetRef,
      });
      // Here we use the gallery compId to save on the document the gallery instance id.
      await editorSDK.document.controllers.saveConfiguration('token', {
        controllerRef: galleryCompRef,
        config: {
          galleryId: createdDraftGalleryId,
        },
      });

      reportGalleryIdToCompIdBI(
        editorSDK,
        biLogger,
        galleryCompRef,
        createdDraftGalleryId,
      );
    }
  } catch (error) {
    console.error('could not create FastGallery app ', error);
  }
}
