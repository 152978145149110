import { IHttpClient } from '@wix/yoshi-flow-editor';
import { getDraftGallery } from '@wix/ambassador-fastgallery-draftgallery-v1-draft-gallery/http';
import { getGalleryByDraftGalleryId } from '@wix/ambassador-fastgallery-gallery-v1-gallery/http';
import { queryItems } from '@wix/ambassador-fastgallery-item-v1-item/http';
import {
  queryDraftItems,
  bulkCreateDraftItems,
  bulkDeleteDraftItems,
  updateDraftItem,
  moveDraftItems,
} from '@wix/ambassador-fastgallery-draftitem-v1-draft-item/http';
import {
  BulkCreateDraftItemsResponse,
  DraftItem,
  Sorting,
  SortOrder,
} from '@wix/ambassador-fastgallery-draftitem-v1-draft-item/types';
import { createFastGalleryApp } from '@wix/ambassador-fastgallery-app-v1-fast-gallery-app/http';
import { ViewMode } from '../types/viewMode';
import { Gallery } from '@wix/ambassador-fastgallery-gallery-v1-gallery/types';
import { WarmupDataManagerType } from '../utils/WarmupDataManager';
import _ from 'lodash';

const ITEMS_LIMIT_DEFAULT = 25;
const BATCH_SIZE = 100;

interface IFetcherParams {
  httpClient: IHttpClient;
  viewMode: ViewMode;
  draftGalleryId: string;
  warmupData?: WarmupDataManagerType;
}

type ProcessBatchFunction<T, R> = (batch: T[]) => Promise<R[] | void>;

const processInBatches = async <T, R>(
  items: T[],
  batchSize: number,
  processBatch: ProcessBatchFunction<T, R>,
): Promise<R[] | void> => {
  const batches = _.chunk(items, batchSize);

  const batchResults = await Promise.all(
    batches.flatMap((batch) => processBatch(batch)),
  );

  const results = batchResults.flatMap((x) => x || []);
  if (results) {
    return results;
  }
};

export class FastGalleryService {
  private httpClient: IHttpClient;
  private viewMode: ViewMode;
  private draftGalleryId: string;
  private warmupData?: WarmupDataManagerType;
  constructor(props: IFetcherParams) {
    const { httpClient, viewMode, draftGalleryId, warmupData } = props;
    this.httpClient = httpClient;
    this.viewMode = viewMode;
    this.draftGalleryId = draftGalleryId;
    this.warmupData = warmupData;
  }

  static async createGalleryInstance(
    httpClient: IHttpClient,
    originGalleryId?: string,
  ) {
    const { data } = await httpClient.request(
      createFastGalleryApp({ originDraftGalleryId: originGalleryId }),
    );
    return data.createdDraftGalleryId;
  }

  private getPublishedGalleryDataWithWarmup() {
    return this.warmupData?.manageData(
      () => this.getPublishedGalleryData(),
      'fastGalleryPublishedData',
    );
  }

  private getPublishedGalleryItemsWithWarmup(galleryId: string, limit: number) {
    return this.warmupData?.manageData(
      () => this.getPublishedGalleryItems(galleryId, limit),
      'fastGalleryPublishedItems',
    );
  }

  async getGalleryItemsInCurrentContext(limit: number = ITEMS_LIMIT_DEFAULT) {
    if (this.viewMode !== 'SITE') {
      return this.getDraftGalleryItems(limit);
    } else {
      const publishedGalleryData =
        await this.getPublishedGalleryDataWithWarmup();
      const publishedGalleryId = publishedGalleryData?.id || '';
      return this.getPublishedGalleryItemsWithWarmup(publishedGalleryId, limit);
    }
  }

  async getGalleryDataInCurrentContext() {
    if (this.viewMode !== 'SITE') {
      return this.getDraftGalleryData();
    } else {
      return this.getPublishedGalleryDataWithWarmup();
    }
  }

  async getDraftGalleryItems(limit: number = ITEMS_LIMIT_DEFAULT) {
    const { data } = await this.httpClient.request(
      queryDraftItems({
        query: {
          filter: {
            draftGalleryId: this.draftGalleryId,
          },
          sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
          cursorPaging: {
            limit,
          },
        },
      }),
    );
    return data.draftItems;
  }

  async getAllDraftGalleryItems() {
    const draftGalleryItems: DraftItem[] = [];
    let hasNext = false;
    let cursor: string | null = null;
    let filter: Record<string, any> | undefined = {
      draftGalleryId: this.draftGalleryId,
    };
    let sort: Sorting[] | undefined = [
      { fieldName: 'sortOrder', order: SortOrder.ASC },
    ];
    do {
      const res = await this.httpClient.request(
        queryDraftItems({
          query: {
            filter,
            sort,
            cursorPaging: {
              limit: BATCH_SIZE,
              cursor,
            },
          },
        }),
      );
      const { draftItems, pagingMetadata } = res.data;
      cursor = pagingMetadata?.cursors?.next || null;
      hasNext = (pagingMetadata?.hasNext as boolean) || false;
      filter = undefined;
      sort = undefined;
      draftItems && draftGalleryItems.push(...draftItems);
    } while (hasNext);

    return draftGalleryItems;
  }

  async getPublishedGalleryItems(
    publishedGalleryId: string,
    limit: number = ITEMS_LIMIT_DEFAULT,
  ) {
    const { data } = await this.httpClient.request(
      queryItems({
        query: {
          filter: {
            galleryId: publishedGalleryId,
          },
          sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
          cursorPaging: {
            limit,
          },
        },
      }),
    );
    return data.items;
  }

  async getDraftGalleryData() {
    const { data } = await this.httpClient.request(
      getDraftGallery({
        draftGalleryId: this.draftGalleryId,
      }),
    );
    return data.draftGallery;
  }

  async getPublishedGalleryData(): Promise<Gallery | void> {
    const { data } = await this.httpClient.request(
      getGalleryByDraftGalleryId({
        draftGalleryId: this.draftGalleryId,
      }),
    );
    return data.gallery;
  }

  async createDraftItemsInServer(
    draftItems: DraftItem[],
    positionFirst?: boolean,
  ): Promise<DraftItem[] | void> {
    const processBatch: ProcessBatchFunction<DraftItem, DraftItem> = async (
      batch,
    ) => {
      const response =
        await this.httpClient.request<BulkCreateDraftItemsResponse>(
          bulkCreateDraftItems({
            draftItems: batch,
            returnEntity: true,
            positionFirst,
          }),
        );
      return (
        response.data.results?.flatMap((result) => result.item || []) || []
      );
    };

    const createdItems = await processInBatches(
      draftItems,
      BATCH_SIZE,
      processBatch,
    );

    if (createdItems) {
      return createdItems;
    }
  }

  async deleteDraftItemsInServer(draftItemsIds: string[]): Promise<void> {
    const processBatch: ProcessBatchFunction<string, void> = async (batch) => {
      await this.httpClient.request(
        bulkDeleteDraftItems({
          draftItemIds: batch,
        }),
      );
    };

    await processInBatches(draftItemsIds, BATCH_SIZE, processBatch);
  }

  async updateSingleDraftItemDataInServer(draftItem: DraftItem): Promise<void> {
    await this.httpClient.request(
      updateDraftItem({
        draftItem,
      }),
    );
  }

  async updateItemSortOrderInServer(
    itemIds: string[],
    afterItemId?: string,
  ): Promise<void> {
    await this.httpClient.request(
      moveDraftItems({
        itemIds,
        afterItemId,
      }),
    );
  }
}
