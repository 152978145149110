export const appDefinitionId = 'b2e8b4cb-1799-4b3c-bace-d55b503f7334';

export const fastGalleryPolyWidgetId = '24c89560-0bba-45d6-b400-d102a138bd4e';
export const fastGallerySlotName = 'fastGallerySlot';

export const fastGalleryPluginsAppDefinitionId =
  '785199b4-c741-4d19-ad2f-060695514c78';
export const fastGalleryPolyPluginWidgetId =
  '2b1e82f2-b7d0-455a-826f-144a74d3d4db';

export enum VIEW_MODES {
  EDITOR = 'EDITOR',
  PREVIEW = 'PREVIEW',
  SITE = 'SITE',
}

export enum MEDIA_MANAGER_MEDIA_TYPE {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  DOCUMENT = 'DOCUMENT',
  PRO_GALLERY = 'PRO_GALLERY',
}
